import React from "react";
import HeroHome from "../components/Homepage/HeroHome";
import Metrics from "../components/Homepage/Metrics";
import CardServices from "../components/Homepage/CardServices";
import WhyChooseUs from "../components/Homepage/WhyChooseUs";
import TheyChoseUs from "../components/Homepage/TheyChoseUs";
import TechSolution from "../components/Homepage/TechSolution";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";

const HomePage: React.FC = () => {
  return (
    <>
      <Navbar />
      <HeroHome />
      <Metrics />
      <CardServices />
      <WhyChooseUs />
      <TheyChoseUs />
      <TechSolution />
      <Footer />
    </>
  );
};

export default HomePage;
